import { Button } from 'src/design-system'
import { Table } from '@tanstack/react-table'
import {
  CaretDoubleLeft,
  CaretDoubleRight,
  CaretLeft,
  CaretRight,
} from '@phosphor-icons/react'
import * as React from 'react'

export type PaginationProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  table: Table<any>
}

export const Pagination = (props: PaginationProps) => {
  const { table } = props

  return (
    <div className="flex flex-row gap-x-1 ml-2 items-center">
      <Button
        className="p-1.5"
        variant={table.getCanPreviousPage() ? 'outline' : 'default'}
        colourVariant="paper"
        title="First page"
        onClick={() => table.setPageIndex(0)}
        disabled={!table.getCanPreviousPage()}
      >
        <CaretDoubleLeft weight="bold" className="w-4 h-4" />
      </Button>
      <Button
        className="p-1.5"
        variant={table.getCanPreviousPage() ? 'outline' : 'default'}
        colourVariant="paper"
        title="Previous page"
        onClick={() => table.previousPage()}
        disabled={!table.getCanPreviousPage()}
      >
        <CaretLeft weight="bold" className="w-4 h-4" />
      </Button>
      <Button
        className="p-1.5"
        variant={table.getCanNextPage() ? 'outline' : 'default'}
        colourVariant="paper"
        title="Next page"
        onClick={() => table.nextPage()}
        disabled={!table.getCanNextPage()}
      >
        <CaretRight weight="bold" className="w-4 h-4" />
      </Button>
      <Button
        className="p-1.5"
        title="Last page"
        variant={table.getCanNextPage() ? 'outline' : 'default'}
        colourVariant="paper"
        onClick={() => table.setPageIndex(table.getPageCount() - 1)}
        disabled={!table.getCanNextPage()}
      >
        <CaretDoubleRight weight="bold" className="w-4 h-4" />
      </Button>
      <div className="ml-2 text-gray-900">
        Page <strong>{table.getState().pagination.pageIndex + 1}</strong> of{' '}
        <strong>{table.getPageCount()}</strong>
      </div>
    </div>
  )
}

import { store } from 'store/index'

export class InsightsSecondaryNavVm {
  get showEngagementTab() {
    return store.featureGates.canAccess('org_insights')
  }

  get showSkillsTab() {
    return !!store.currentUser && store.currentUser.allReports.length > 0
  }

  get showSkillGapsTab() {
    return store.featureGates.canAccess('org_insights')
  }
}

import * as React from 'react'
import { InsightsSecondaryNavVm } from './insights-secondary-nav-vm'
import { store } from 'store/index'
import { trackEvent } from 'app/packs/src/services/event-tracker'
import * as SecondaryNav from 'components/secondary-nav'

type InsightsSecondaryNavProps = {
  actions?: React.ReactNode
}

export const InsightsSecondaryNav = (props: InsightsSecondaryNavProps) => {
  const { actions } = props

  const vm = React.useMemo(() => {
    return new InsightsSecondaryNavVm()
  }, [])

  const currentUser = store.currentUser

  if (!currentUser) return null

  const org = currentUser.org

  if (!org) return null

  const basePath = `${org.basePath}/insights`

  const onClick = (tab: 'Engagement' | 'Skills' | 'Skill Gaps') => {
    trackEvent('$track_org_insights_tab_clicked', { tab })
  }

  return (
    <SecondaryNav.Root className="items-end md:justify-end pt-0">
      <SecondaryNav.Header className="mb-0">Insights</SecondaryNav.Header>
      <SecondaryNav.Body className="flex-shrink justify-between w-full">
        <SecondaryNav.Tabs>
          {currentUser.isAdmin && vm.showSkillGapsTab && (
            <SecondaryNav.Tab
              href={`${basePath}/skill-gaps`}
              onClick={() => onClick('Skill Gaps')}
            >
              Skill Gaps
            </SecondaryNav.Tab>
          )}
          {currentUser.isAdmin && vm.showEngagementTab && (
            <SecondaryNav.Tab
              href={`${basePath}/engagement`}
              onClick={() => onClick('Engagement')}
            >
              Engagement
            </SecondaryNav.Tab>
          )}
        </SecondaryNav.Tabs>
        {actions}
      </SecondaryNav.Body>
    </SecondaryNav.Root>
  )
}
